import { useState, type ReactElement } from 'react'
import { motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

export function Discover(): ReactElement {
  const [isSelected, setIsSelected] = useState(false)

  return (
    <div className="relative mb-2 h-[40px] w-[170px] desktop:w-[230px]">
      <button
        className={twMerge(
          'absolute z-10 size-full rounded-md text-sm font-semibold transition-all duration-300 ease-in-out desktop:text-base',
          isSelected
            ? 'bg-dark-black text-white'
            : 'bg-primary-main text-dark-black',
        )}
        onClick={() => {
          setIsSelected(!isSelected)
        }}
      >
        Conheça a plataforma
      </button>
      <motion.button
        className="absolute top-0 flex size-full items-center justify-center rounded-md bg-primary-main"
        initial={{ y: 0 }}
        animate={{ y: isSelected ? 42 : 0 }}
        transition={{ duration: 0.05, ease: 'easeInOut' }}
        onClick={() => {
          window.location.href = 'https://gandaya.dance'
        }}
      >
        <span className="text-xs font-semibold desktop:text-sm">
          Explorar eventos
        </span>
      </motion.button>
      <motion.button
        className="absolute top-0 flex size-full items-center justify-center rounded-md bg-primary-main"
        initial={{ y: 0 }}
        animate={{ y: isSelected ? 84 : 0 }}
        transition={{ duration: 0.05, ease: 'easeInOut' }}
        onClick={() => {
          window.location.href = 'https://studio.gandaya.dance/'
        }}
      >
        <span className="text-xs font-semibold desktop:text-sm">
          Crie seu evento
        </span>
      </motion.button>
      <motion.button
        className="absolute top-0 flex size-full items-center justify-center rounded-md border border-primary-main bg-dark-black text-white"
        initial={{ y: 0 }}
        animate={{ y: isSelected ? 126 : 0 }}
        transition={{ duration: 0.05, ease: 'easeInOut' }}
        onClick={() => {
          window.location.href =
            'https://gdya-public.s3.us-east-2.amazonaws.com/gandaya-B2B-v5.pdf'
        }}
      >
        <span className="text-xs font-semibold desktop:text-sm">
          Saiba mais
        </span>
      </motion.button>
    </div>
  )
}
