import { type ReactElement, useState } from 'react'
import { Player, Controls } from '@lottiefiles/react-lottie-player'
import { twMerge } from 'tailwind-merge'

import { Card } from '../../components/Card'
import { Discover } from '../../components/Discover'

import bgGif from '../../assets/gif-landingpage.gif'
import logo from '../../assets/logo-gandaya-clean.png'
import arrowAnimation from '../../assets/arrowAnimation.json'
import img1 from '../../assets/img1.png'
import img2 from '../../assets/img2.png'
import img3 from '../../assets/img3.png'
import img4 from '../../assets/img4.png'

import { FaWhatsapp } from 'react-icons/fa'

export function Home(): ReactElement {
  const [currentImage, setCurrentImage] = useState<
    'img1' | 'img2' | 'img3' | 'img4' | null
  >()

  function scrollToContent(): void {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth',
    })
  }

  function handleWhatsApp(): void {
    window.location.href =
      'https://api.whatsapp.com/send?phone=5511989280302&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Gandaya.'
  }

  return (
    <div className="size-fit">
      <button
        className="absolute bottom-4 right-4 z-20 flex size-12 items-center justify-center rounded-md bg-primary-main"
        onClick={handleWhatsApp}
      >
        <FaWhatsapp size={28} color="#181818" />
      </button>

      <div className="relative flex h-dvh w-dvw items-center justify-center">
        <img
          src={bgGif}
          alt="Background gif"
          className="absolute size-full object-cover brightness-50"
        />

        <div className="absolute top-8 flex h-fit w-full items-center justify-between px-4 desktop:px-8">
          <img src={logo} alt="Gandaya logo" className="w-2/5 max-w-[250px]" />
          <Discover />
        </div>

        <span className="z-10 text-center font-realce text-[100px] leading-[80px] text-white desktop:text-[300px] desktop:leading-[250px]">
          LET&apos;S <br />
          DANCE
        </span>

        <button
          className="absolute bottom-0 left-1/2 z-10 size-[50px] -translate-x-1/2 desktop:size-[100px]"
          onClick={scrollToContent}
        >
          <Player
            autoplay
            loop
            src={arrowAnimation}
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            <Controls buttons={['play', 'repeat', 'frame', 'debug']} />
          </Player>
        </button>
      </div>

      <div className="relative flex h-dvh w-dvw flex-col items-center justify-center">
        <div className="flex size-full flex-col desktop:flex-row">
          <div
            className="relative flex size-full items-center justify-center"
            onMouseEnter={() => {
              setCurrentImage('img1')
            }}
            onMouseLeave={() => {
              setCurrentImage(null)
            }}
          >
            <img
              src={img1}
              alt="Imagem 1"
              className={twMerge(
                'absolute size-full object-cover brightness-50',
                currentImage === 'img1' && 'blur-sm',
              )}
            />

            <Card
              frontText="VENDA DE INGRESSOS PERSONALIZADA"
              backText="Personalização completa do site de venda de ingressos para cada evento. Integre detalhes como line up, playlists, gifs animados, informações sobre o evento e localização com mapa integrado, proporcionando uma experiência única e interativa."
              showInfo={currentImage === 'img1'}
            />
          </div>
          <div
            className="relative flex size-full items-center justify-center"
            onMouseEnter={() => {
              setCurrentImage('img2')
            }}
            onMouseLeave={() => {
              setCurrentImage(null)
            }}
          >
            <img
              src={img2}
              alt="Imagem 2"
              className={twMerge(
                'absolute size-full object-cover brightness-50',
                currentImage === 'img2' && 'blur-sm',
              )}
            />

            <Card
              frontText="CONEXÃO SOCIAL"
              backText="Cada cliente pode criar perfis personalizados, seguir amigos, visualizar quem está interessado nos eventos, reagir com emojis às festas futuras e decorar seus perfis com stickers das festas que frequentaram, promovendo interações sociais dinâmicas e personalizadas."
              showInfo={currentImage === 'img2'}
            />
          </div>
        </div>

        <button
          className="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 rounded-full border border-dark-black bg-gradient-to-r from-white to-primary-main px-6 py-1 text-xs font-medium desktop:text-xl"
          onClick={() => {
            window.location.href =
              'https://gdya-public.s3.us-east-2.amazonaws.com/gandaya-B2B-v5.pdf'
          }}
        >
          Saiba mais
        </button>

        <div className="flex size-full flex-col desktop:flex-row">
          <div
            className="relative flex size-full items-center justify-center"
            onMouseEnter={() => {
              setCurrentImage('img3')
            }}
            onMouseLeave={() => {
              setCurrentImage(null)
            }}
          >
            <img
              src={img3}
              alt="Imagem 3"
              className={twMerge(
                'absolute size-full object-cover brightness-50',
                currentImage === 'img3' && 'blur-sm',
              )}
            />
            <Card
              frontText="REVENDA INTEGRADA"
              backText="Solução integrada de revenda de ingressos que é segura e confiável. Eliminamos preocupações com fraudes, sem necessidade de capturas de tela (prints), pagamentos por fora da plataforma ou riscos de golpes."
              showInfo={currentImage === 'img3'}
            />
          </div>
          <div
            className="relative flex size-full items-center justify-center"
            onMouseEnter={() => {
              setCurrentImage('img4')
            }}
            onMouseLeave={() => {
              setCurrentImage(null)
            }}
          >
            <img
              src={img4}
              alt="Imagem 4"
              className={twMerge(
                'absolute size-full object-cover brightness-50',
                currentImage === 'img4' && 'blur-sm',
              )}
            />
            <Card
              frontText="CASHLESS"
              backText="Consumação em eventos mais simples e eficiente. Consumidores podem carregar créditos diretamente pela Gandaya, eliminando a necessidade de esperar em filas para obter um cartão de consumo. Compras podem ser feitas rapidamente através do celular, proporcionando conveniência total."
              showInfo={currentImage === 'img4'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
